function Footer() {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__text">Created by Maria Sidko</div>
      </div>
    </div>
  );
}
export default Footer;
